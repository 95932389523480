<template>
  <section class="applicationCard">
    <div class="applicationCard__containerRow">
      <img class="applicationCard__logo" :src="app.icon" alt="logo" />
      <p class="applicationCard__appName">{{ app.name }}</p>
    </div>
    <div class="applicationCard__containerRow">
      <select class="applicationCard__input" v-model="app.role" @change="selecTest">
        <option value="">Seleccione</option>
        <option v-for="role in rolesOptions" :value="role.key" :key="role.key">{{ role.name }}</option>
      </select>
      <input class="mycheck " type="checkbox" name="role" v-model="app.isSelected" :disabled="!app.role" @change="selectOptionApp" />
    </div>
  </section>
</template>

<script>
export default {
  props: ["app", "selectApp"],
  data() {
    return {
      roles: this.$global.dictionary.roles_es,
      rolesOptions: [],
    };
  },
  methods: {
    createRolesOptions() {
      return Object.entries(this.roles).map(([name, key]) => ({ name, key }));
    },
    selectOptionApp() {
      const { key, isSelected, role } = this.app;

      const setSelected = !role ? false : isSelected;

      this.selectApp(key, setSelected, role);
    },
    selecTest() {
      const { role } = this.app;

      this.app.isSelected = !!role;

      this.selectOptionApp();
    },
  },
  mounted() {
    this.rolesOptions = this.createRolesOptions();
  },
};
</script>

<style lang="scss">
.applicationCard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  width: 100%;
  gap: 15px;
  padding: $mpadding;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  &__logo {
    width: 48px;
    height: 48px;
  }
  &__containerRow {
    @include Flex(row, flex-start);
    gap: $mpadding;
  }
  &__appName {
    text-transform: capitalize;
  }
  &__input {
    width: 100%;
    height: 40px;
    border: 1px solid $alto;
    border-radius: 6px;
    padding: 5px;
    text-transform: capitalize;
    &:focus {
      outline: none;
    }
  }
  .mycheck {
    width: 15px;
    height: 15px;
    padding: 9px;
    border-radius: 50%;
    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}
</style>
